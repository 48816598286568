.inputs_flex{
    display: flex;
    justify-content: center;

}
.input_flex_direction{
    display: flex;
    flex-direction: column;
}
.adm_input{
    margin-top: 15px;
}
.adm_input input{
    width: 350px;
    height: 40px;
}
.adm_input textarea{
    width: 350px;
    height: 180px;
}
.marginLeft{
    margin-left: 15px;
}
.add_blog_div{
    display: flex;
    justify-content: center;
    width: 305px;
}

.add_blog{
    width: 70px;
    height: 40px;
}
.content_flex_direction{
    display: flex;
    flex-direction: column;
}
.item{
    display: flex;
    justify-content: center;
    width: 500px;
}
.item_column{
    display: flex;
    justify-content: center;
}
.item img{
    width: 500px;
}
.item_center{
    display: flex;
    flex-direction: column;
}
.search_flex{
    width: 100%;
    display: flex;
    justify-content: center;
}
.search_flex_input{
    margin-top: 15px;
    height: 40px;
}
.login_input_flex{
    display: flex;
    width: 100%;
    height: 50vh;
    justify-content: center;
    align-items: center;
}
.login_input_flex_direction{
    display: flex;
    flex-direction: column;
}
.ourGames__card-logo img{
    width: 300px;
}
.content_flex{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.justify{
    display: flex;
    flex-direction: row;
    width: 100%;   
    justify-content: space-around;
}
.ourGames__card-description{
    width: 300px;
}
.ourGames__card-title{
    margin-top: 35px;
}
.cursor_pointer{
    cursor: pointer;
}
.color_white{
    color: white;
}
.try_again_text{
    color: red;
    font-size: 18px;
}
.succsesfully{
    color: green;
    font-size: 18px;
}
.edit_button{
    margin-left: 15px;
}