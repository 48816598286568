.newsList{
    background: #DBDEE3;
    padding: 70px 0;

    @media screen and ( max-width:860px){
        padding: 30px 0;
    }
}
.news_width_flex{
    display: flex;
    width: 100%;
    justify-content: center;
}
.newsList__col{
    padding-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    

    @media screen and (max-width:1921px) {
        width: 1920px;
    }

    @media screen and (max-width: 1100px) {
        justify-content: center;
        flex-wrap: wrap;
    }

    @media screen and ( max-width:860px){
        padding-top: 30px;
    }
}

.newsList__row{
    width: 28%;
    margin-top: 25px;
    box-shadow: 0px 1px 30px 1px rgba(39,43,71,0.59);
    border-bottom-right-radius: 33px;
    border-top-left-radius: 33px;
    margin-left: 25px;
    @media screen and (max-width: 1921px){
        width: 28%;
    }

    @media screen and ( max-width: 1100px){
        margin: 0 40px 40px;
        width: 44%;
    }

    @media screen and ( max-width: 826px){
        margin: 0 0 30px;
        width: 100%;
    }

    // &:not(:last-child){
    //     margin-right: 55px;

    //     @media screen and ( max-width: 1100px){
    //         margin-right: 0px;
    //     }
    // }

    .newsList__img{
        width: 100%;
        height: 35%;

        @media screen and ( max-width: 1100px){
            height: 44%;
        }

        @media screen and ( max-width: 924px){
            height: 35%;
        }

        @media screen and ( max-width: 826px){
            height: 50%;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 33px;
        }
    }

    .newsList__text{
        padding: 30px;
        height: 70vh;
        color: #272b47;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width: 1216px) {
            padding: 15px;
        }
        @media screen and (max-width: 420px) {
            padding: 15px;
            height: 85vh;
            justify-content: flex-start;
        }

        h2{
            text-align: center;
        }
        
        p{
            text-align: left;
            padding: 20px 0;

            @media screen and (max-width: 1216px) {
                padding-bottom: 0px;
            }
        }
    }
}