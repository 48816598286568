.newsPage{
    background-color: #DBDEE3;
    padding: 70px 0;

    @media screen and ( max-width: 860px){
        padding: 30px 0;
    }
}

.newspage__card{
    color: #272b47;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;

    .flex_content{
        display: flex;
        
        flex-direction: column;
        
    }
    .justify_content{
        display: flex;
        justify-content: center;
    }

    .image_part{
        width: 300px;
        height: 300px;
        float: left;
    }
    .image_part img{
        width: 300px;
        height: 300px;
        float: left;
    }
    #container{
        width: 100%;


        @media screen and ( max-width: 1035px){
            width: 90%;
        }

        @media screen and ( max-width: 769px){
            display: flex;
            flex-direction: column;
            background-color: #272b47;
        }
        
    }
    
    #floated img{
        float: left;
        width: 500px;
        height: 330px;
        z-index: 11;
        position: relative;


        @media screen and ( max-width: 1920px){
            width: 95%;
            height: 95%;
        }
        @media screen and ( max-width: 1559px){
            width: 95%;
            height: 100%;
        }
        @media screen and ( max-width: 1035px){
            width: 300px;
            height: 100%;
        }
        @media screen and ( max-width: 769px){
            width:80%;
            height: 100%;
            
        }
    }
    #floated{
        margin-top: 40px;
        display: flex;
        margin-left: 30px;
        align-items: center;
        float: left;
        width: 560px;
        border: none;
        height: 350px;
        z-index: 11;
        position: relative;


        @media screen and ( max-width: 1920px){
            width: 49%;
            height: 40%;
        }
        @media screen and ( max-width: 1559px){
            width: 49%;
            height: auto;
        }

        @media screen and ( max-width: 1035px){
            width:320px;
            height: auto;
        }
        @media screen and ( max-width: 769px){
            display: flex;
            width:100%;
            margin-left: 0px;
            justify-content: center;
            align-items: center;
        }
        @media screen and ( max-width: 769px){
            width:100%;
            height: 90%;
            
        }
    }
    .content{
        width: 100%;
        display: flex;
        
    }

    .text_part{
        z-index: 1;
        margin-top: 10px;
        text-align: justify;
        background: #272b47;
        padding: 33px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        box-shadow: 0px 1px 30px 1px rgba(39,43,71,0.59);

        @media screen and ( max-width: 860px){
            margin-top: -5px;
        }

        @media screen and ( max-width: 626px){
            padding: 22px;
        }

        p{
            padding-bottom: 15px;
            color: #DBDEE3;
            font-size: 18px;
            font-weight: 500;

            @media screen and ( max-width: 626px){
                font-size: 15px;
                padding-bottom: 8px;
            }
        }
    }

    &-img{
        width: 100%;
        height: 100%;
        
        img{
            float: right;
            width: 100%;
            object-fit: cover;
        }
    }
}