


.main{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
    padding-top: 10px 0 0 10px;
}


.admin_inputs{
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.example__image{
    height: 20%;
    width: 400px;
}
.admin_input1 input{
    width: 500px;
    height: 40px;
    margin-top: 15px;
}
.admin_inputs_column{
    display: flex;
    flex-direction: column;
}

.admin_textarea textarea{
    width: 500px;
    margin-top: 15px;
}
.admin_file_input input{
    margin-top: 15px;
}
.create_admin_post button{
    margin-top: 15px;
    width: 90px;
    height: 40px;
    cursor: pointer;
}
.admin_file_input{
    display: flex;
    flex-direction: column;
}

.select_category{
    margin-top: 15px;
}

.centered_div{
    display: flex;
    width: 500px;
    justify-content: center;
}
.second_photo_label{
    margin-top: 15px;
}
.succses_upload{
    color: rgb(12, 216, 12);
    font-size: 18;
}
.reject_upload{
    color: red;
    font-size: 18;
}
.tags{
    display:flex;
    flex-direction: row;
    height: 50px;
}
.tag_input input{
    width: 80px;
    height: 20px;
    margin-left: 15px;

}

.color_white{
    color: white;
}

.delete_button{
    cursor: pointer;
    width: 50px;
}
.flex_direction_class{
    display: flex;
    flex-direction: column;
}
.login_block{
    display: flex;
    justify-content: center;
}
.login_block_flex{
    display: flex;
    flex-direction: column;
}
.login_button{
    margin-top: 15px;
    
}

.content_buttons{
    display: flex;
}
.edit_button{
    height: 40px;
    width: 50px;
}

.search_blog{
    height: 60px;
    margin-top: 15px;
}
.search_blog input{
    height: 40px;
}
.height_block{

    display: flex;
    flex-direction: column;
}
.height_div{
    height: 50px;
}
.imageUrl{
    color: green;
    width: 520px;
}
.clear_photo_url_button{
    width: 90px;
    margin-top: 15px;
    height: 40px;
    text-align: center;
}
.url_text{
    margin-top: 15px;
}

.image_content{
    width: 500px;
    height: auto;
}