@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

*, *:before, *:after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
}

html, body {
  width: 100%;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  background: #272b47;
}

.container {
  margin: 0 auto;
  max-width: 1550px;
  padding: 0 10px;
}

.container__back-white{
  background: #DBDEE3;
}

.ourGames{
  background: #DBDEE3;
  padding-top: 70px;

  @media screen and ( max-width: 860px){
    padding-top: 30px;
  }
}

.link-redirect{
  color: #272b47;
  font-weight: 900;
  text-decoration: underline;
}

.title{
  color: #272b47;
  text-align: center;
  font-family: Brutal_Light, 'Open Sans', arial;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  
  @media screen and ( max-width: 860px){
    font-size: 40px;
  }
  @media screen and ( max-width: 626px){
    font-size: 33px;
  }
}

.ourGames__p{
  color: #272b47;
  line-height: 30px;
  text-align: center;
  margin: 0 auto;
  padding: 70px 0;
  width: 66%;

  @media screen and ( max-width: 860px){
    width: 100%;
    padding: 30px 0;
  }
}

.button{
  padding: 11px;
  margin-top: 30px;
  display: inline-flex;
  background: #DBDEE3;
  color: #272b47;
  letter-spacing: 2px;
  font-family: Brutal_Bold, 'Open Sans', arial;
  font-weight: 700;
  font-size: 15px;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;

  &:hover{
    color: #DBDEE3;
    background: rgb(0, 87, 217);
    box-shadow: rgb(0, 87, 217) 0px 0px 18px;
  }
}

.btn {
  .link {
    color: #272b47;;
    // text-transform: uppercase;
    letter-spacing: 2px;
    font-family: Brutal_Bold, 'Open Sans', arial;
    font-weight: 700;
    font-size: 13px;
    transition: all 0.5s ease 0s;
    @media (max-width: 300px) {
      padding: 10px;
      letter-spacing: 1px;
      font-size: 11px;
    }
  }
}
.button-border-gradient {
  .link {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: Brutal_Bold, 'Open Sans', arial;
    font-weight: 700;
    font-size: 13px;
    padding: 13px 24px;
    border: 2px solid;
    background: #272b47;
    color: #DBDEE3;
    border-image-slice: 1;
    border: none;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;

    &:hover {
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
      -moz-transition: all 0.5s ease 0s;
      -ms-transition: all 0.5s ease 0s;
      -o-transition: all 0.5s ease 0s;
      background: rgb(0, 87, 217);
      box-shadow: rgb(0, 87, 217) 0px 0px 18px;
    }
  }
}

.button-underline {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    bottom: 0;
    background: radial-gradient(
      100% 185.14% at 100% 100%,
      rgb(52, 195, 255) 0%,
      rgb(51, 142, 238) 100%
    );
  }

  padding: 12px 0 5px;
  &:hover {
    &::before {
      animation: animateLine 1s;
    }
    
  }
}
@keyframes animateLine {
  0% {
    width: 10%;
  }
  100% {
    width: 100%;
  }
}

.buttons{
  cursor: pointer;
  text-transform: uppercase;
  padding: 18px 49px;
  border: 1px solid white;
  background-color:#fff;
  color: #272b47;;
  font-weight: 900;
  margin-top: 35px;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;

  &:hover{
      background: #009DDF;
      color: white;
  }
}

// media
@media screen and (max-width: 1551px){
  .container {
    padding: 0 140px;
  }
}

@media screen and (max-width: 1280px){
  .container {
    padding: 0 115px;
  }
}

@media screen and (max-width: 1133px){
  .container {
    padding: 0 80px;
  }
}
@media screen and (max-width: 1551px){
  .container {
    padding: 0 140px;
  }
}
@media screen and (max-width: 1280px){
  .container {
    padding: 0 115px;
  }
}
@media screen and (max-width: 1133px){
  .container {
    padding: 0 80px;
  }
}
@media screen and (max-width: 672px){
  .container {
    padding: 0 16px;
  }
}