


.tags{
    display:flex;
    flex-direction: row;
    height: 50px;
}
.tag_input input{
    width: 80px;
    height: 20px;
    margin-left: 15px;
    margin-top: 15px;

}
.tags_input{
    display: flex;
    flex-direction: row;
    height: 50px;
}
.edit_input{
    margin-top: 15px;
    height: 40px;
}

.edit_textarea{
    margin-top: 15px;
}