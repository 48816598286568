

.ourGames__box{
    padding: 70px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and ( max-width: 860px) {
        padding: 30px 0;
    }

    @media screen and ( max-width: 565px) {
        flex-wrap: wrap;
        justify-content: center;
    }
    


    

    .ourGames__card{
        width: 33%;
        height: 100%;
        background-color: #262a46;
        z-index: 1;
        position: relative;
        padding: 33px;
        margin-left: 25px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-top-left-radius: 55px;
        border-bottom-right-radius: 55px;

        .ourGames__card.marginLeft{
            margin-left: 35px;
        }
        
        @media screen and ( max-width: 1094px) {
            width: 44%;
        }

        @media screen and ( max-width: 860px) {
            padding: 15px;
        }

        @media screen and ( max-width: 565px) {
            width: 77%;
        }
        
        &-title{
            background-color: #DBDEE3;
            color: #272b47;
            -webkit-box-shadow: 0px 3px 22px 3px rgba(39,43,71,0.59);
            box-shadow: 0px 3px 22px 3px rgba(39,43,71,0.59);
            padding: 5px 0;
            z-index: 2;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: -17px;
            text-align: center;
            width: 66%;
            font-weight: 500;
            font-size: 20px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-bottom-left-radius: 55px;
            border-top-right-radius: 55px;

            // @media screen and ( max-width: 626px) {
            //     font-size: 15px;
            // }
        }
        &-logo{
            width: 100%;
            height: 100%;
            text-align: center;
            margin: 11px 0;

            img{
                width: 33%;
                height: auto;
                object-fit: cover;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                -webkit-box-shadow: 0px 1px 33px 1px #DBDEE3;
                box-shadow: 0px 1px 33px 1px #DBDEE3;
            }
        }
        
        &-links{
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and ( max-width: 860px) {
                flex-wrap: wrap;
            }

            @media screen and ( max-width: 778px) {
                justify-content: center;
            }

            img{
                width: 121px;
                height: 44px;

                @media screen and ( max-width: 403px) {
                    width: 111px;
                    height: 33px;
                }
            }
        }

        &-game{
            width: 100%;
            height: 100%;
            text-align: center;
            position: relative;
            margin: 11px 0;
            cursor: pointer;
    
            &:hover{
                .ourGames__card-description{
                    opacity: 1;
                    visibility: visible;
                }

                img{
                    opacity: .4;
                    transform: scale(.9);
                    -webkit-transform: scale(.9);
                    -moz-transform: scale(.9);
                    -ms-transform: scale(.9);
                    -o-transform: scale(.9);

                    @media screen and ( max-width: 403px) {
                        opacity: 1;
                        transform: scale(1);
                        -webkit-transform: scale(1);
                        -moz-transform: scale(1);
                        -ms-transform: scale(1);
                        -o-transform: scale(1);
                    }
                }
            }

            .ourGames__card-description{
                text-align: justify;
                position: absolute;
                top: 0;
                opacity: 0;
                visibility: hidden;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
                
                p{
                    color: #DBDEE3;
                    font-weight: 600;
                    text-align: left;
                    line-height: 1.2;

                    @media screen and ( max-width: 860px) {
                        font-size: 15px;
                        line-height: 1.1;
                    }

                    @media screen and ( max-width: 765px) {
                        font-size: 13px;
                    }

                    @media screen and ( max-width: 565px) {
                        font-size: 15px;
                        line-height: 1.2;
                    }

                    @media screen and ( max-width: 403px) {
                        display: none;
                    }
                }
            }

            img{
                width: 100%;
                height: auto;
                object-fit: cover;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
            }
        }

        .youtube-icon{
            color: red;
            transition: all .5s ease;
            -moz-transition: all .5 ease;
            -ms-transition: all .5 ease;
            -o-transition: all .5 ease;
            font-size: 55px;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            margin-top: 5px;

            @media screen and ( max-width: 860px){
                margin-top: -13px;
            }

            &:hover{
                transform: scale(.9);
                -webkit-transform: scale(.9);
                -moz-transform: scale(.9);
                -ms-transform: scale(.9);
                -o-transform: scale(.9);
            }
        }
    }
    

    .ourGames__card:not(:last-child){
        margin-bottom: 50px;

        @media screen and ( max-width: 565px) {
            margin-bottom: 70px;
        }
    }

}


.box_center{
    justify-content: center;
    flex-wrap: wrap;
}
    
.card_margin:nth-child(2n+1){
    margin-right: 50px;

    @media screen and ( max-width: 565px){
        margin-right: 0px;
    }
}

