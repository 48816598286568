.growingStudio{
    background: #272b47;
}

.teamabout{
    display: flex;
    justify-content: space-between;
    padding-top: 70px;

    @media screen and ( max-width: 860px){
        padding-top: 30px;
    }

    @media screen and ( max-width: 626px){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .teamabout__box-left{
        display: flex;

        @media screen and ( max-width: 626px){
            display: flex;
            margin: 0 auto;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin-bottom: 70px;
        }

        h2{
            color: #DBDEE3;
            font-size: 65px;
            font-weight: 400;
            line-height: 110%;
            position: relative;
            width: 55%;

            @media screen and ( max-width: 980px){
                font-size: 44px;
            }
        }

        img{
            position: absolute;
            right: auto;
            bottom: auto;
            left: 22%;

            @media screen and ( max-width: 626px){
                left: 35%;
            }
        }
    }

    .teamabout__box-right{
        width: 66%;
        
        p{
            text-align: justify;
            color: white;
            font-size: 22px;
            line-height: 29px;
            font-weight: 300;

            @media screen and ( max-width: 980px){
                font-size: 18px;
                line-height: 25px;
            }
        }
    }

        
    
}

