.news{
    background-color: #dbdee3;
    // padding-bottom: 70px;

    @media screen and ( max-width: 860px){
      padding-bottom: 30px;
    }
    @media screen and ( max-width: 7930px){
      padding-bottom: 0px;
    }
    .news_cards {
        display: flex;
        justify-content: space-between;
        padding-bottom: 90px;

        @media (max-width: 900px) {
          flex-wrap: wrap;  
          justify-content: center;
          text-align: center;
        }
        
        @media (max-width: 793px) {
          padding-bottom: 30px;
          text-align: left;
        }
    }
    .title_part{
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        margin: 0 12px;
        
        @media (max-width: 790px) {
          flex-wrap: wrap;
        }

      @media (max-width: 610px) {
          padding-top: 10px;
      }
      h3{
          font-size: 34px;
          font-weight: 300;
          line-height: 42px;
          font-family: Brutal_Light, 'Open Sans', arial;
          padding-right: 50px;
      }
}
}

.news_card {
    position: relative;
    padding: 30px 0;
    margin: 12px;
    font-family: Brutal_Regular, 'Open Sans', arial;
    font-weight: 400;
    line-height: 30px;
    min-width: 240px;
    // width: calc((1280px - 90px)/3);
    width: calc((100% - 90px)/3);
    @media (max-width: 900px) {
      width: 45%;

      &:last-child{
        margin-top: 33px;
      }
    }
    @media (max-width: 793px) {
      width: 95%;
      margin: 0;

      &:last-child{
        margin-top: 0px;
      }
    }
    @media (max-width: 424px) {
      padding: 20px 0;
    }
    @media (max-width: 300px) {
      margin: 0;
    }
    .img{
      overflow: hidden;
      height: 55%;
      box-shadow: 0px 1px 30px 1px rgba(39,43,71,0.59);
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s ease 0s;
        cursor: pointer;
        -webkit-transition: all .5s ease 0s;
        -moz-transition: all .5s ease 0s;
        -ms-transition: all .5s ease 0s;
        -o-transition: all .5s ease 0s;
       
        &:hover{
          transform: scale(1.1);
          transition: all .5s ease 0s;
        }
      }

      @media (max-width: 793px) {
        height: 282px;
      }
      @media (max-width: 424px) {
        height: 202px;
      }
    }
    .category{
      text-transform: uppercase;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 2px;
      padding: 20px 0 10px;
    }
    h4 {
      font-size: 22px;
      padding-bottom: 20px;
      .link {
        color: #272b47;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;

        &:hover {
          color: rgb(0, 87, 217);
        }
      }

    }
    p{
      color: #383b50;
      display: block;
      margin-bottom: 15px;

      @media (max-width: 793px) {
        text-align: justify;
      }
    }
   
    
}
  