.ourGames__row{
    padding-bottom: 70px;

    @media screen and ( max-width: 860px){
        padding-bottom: 30px;
    }
}

.ourGames__col{
    padding: 25px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    
    @media screen and ( max-width: 860px){
        padding: 20px;
    }
    
    &:nth-child(1),&:nth-child(3),&:nth-child(5){
        // border: 1px solid #272b47;
        -webkit-box-shadow: 0px 1px 30px 1px rgba(39,43,71,0.59); 
        box-shadow: 0px 1px 30px 1px rgba(39,43,71,0.59);
        background: linear-gradient(135deg, transparent 50px, #DBDEE3 0), linear-gradient(-135deg, transparent 50px, #272b47 0);

        @media screen and ( max-width: 860px){
            background: linear-gradient(135deg, transparent 30px, #DBDEE3 0), linear-gradient(-135deg, transparent 30px, #272b47 0);
        }
    }
    
    .ourGames__col-title{
        text-align: center;
        color: #272b47;
        margin-top: 15px;
    }

    .ourGames__col-text{
        color: #272b47;
        line-height: 22px;
        text-align: justify;
        padding: 30px 0;
        width: 100%;
        
        @media screen and ( max-width: 860px){
            padding: 20px 0;
        }

        @media screen and ( max-width: 626px){
            font-size: 15px;     
            padding: 22px 0;   
        }
    }
    
    .ourGames__col-flex{
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and ( max-width: 860px){
            flex-direction: column;
        }
    }
    
    .ourGames__col-img{
        width: 88%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: right;

        @media screen and ( max-width: 860px){
            width: 100%;
            justify-content: center;
        }

        img{
            width: 66%;
            height: auto;
            object-fit: cover;

            @media screen and ( max-width: 860px){
                width: 25%;
            }
            @media screen and ( max-width: 434px){
                width: 44%;
            }
        }
        
    }
}