.privacy{
    background: #DBDEE3;
    color: #272b47;
    padding: 70px 0;

    p{
        font-size: 15px;
        line-height: 33px;
        text-align: justify;
    }

    h4{
        font-size: 25px;
        line-height: 77px;
    }

    strong{
        font-size: 15px;
        line-height: 33px;
        font-weight: 900;
    }
}