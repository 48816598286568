.footer{
    background: #272b47;

    &__top{
        padding: 70px 0px;
        margin-top: 70px;
        border-bottom: 2px solid #DBDEE3;
        border-top: 2px solid #DBDEE3;

        @media screen and ( max-width: 860px){
            padding: 30px 0;
            margin-top: 30px;
        }
    }

    &__top-flex{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__icons{
        // margin-top: 40px;
        
        .white{
            color: #DBDEE3;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
            font-size: 35px;

            &:hover{
                color: #009DDF;
            }

            @media screen and ( max-width: 626px){
                font-size: 30px;
            }
            @media screen and ( max-width: 343px){
                font-size: 22px;
            }
        }
    }
      
    &__i{
        cursor: pointer;
        color: white;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 2px;
        font-weight: 600;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;

        &:hover{
            color: #0E90DF;
        }

        .footer__i-icon{
            margin-bottom: -5px;
            font-size: 20px;
        }

        @media screen and ( max-width: 626px){
            font-size: 10px;
        }
    }

    &__nav{
        display: flex;
        justify-content: space-between;
        margin-top: 33px;

        
            @media screen and ( max-width: 777px){
                // text-align: center;
                flex-wrap: wrap;
                // justify-content: center;
            }

        .footer__list{
            color: #C6BCB4;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 3px;

            @media screen and ( max-width: 626px){
                font-size: 10px;
            }

            @media screen and ( max-width: 402px){
                &:last-child{
                    margin-top: 22px;
                    margin: 0 auto;
                    text-align: center;
                }
            }

            li:first-child{
                margin-top: 16px;
            }

            li:not(:last-child){
                margin-bottom: 12px;
            }
        }

        .footer__list-flex{
            display: flex;
            align-items: center;
        }

        .footer__li-icon{
            font-size: 17px;
            color: white;
            margin-right: 5px;
        }

        .footer__list:not(:last-child){
            margin-right: 7px;
        }

        .footer__link{
            color: white;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
            font-weight: 400;       
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.2px;

            &:hover{
                color: #009DDF;
            }

            @media screen and ( max-width: 626px){
                font-size: 11px;
                line-height: 0;
            }
        }

        .footer__downloads{
            width: 150px;
            height: 50px;
            cursor: pointer;

            @media screen and ( max-width: 626px){
                width: 88px;
                height: 33px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__bottom{
        padding: 33px 0;

        p{
            color: white;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 0.2px;
            display: block;

            @media screen and ( max-width: 626px){
                font-size: 11px;
            }
        }

        .footer__bottom-flex{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .footer__bottom-card{

            .footer__bottom-links{
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.2px;
                color: white;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;

                &:hover{
                    color: #009DDF;
                }

                @media screen and ( max-width: 626px){
                    font-size: 11px;
                }
            }

            .footer__bottom-links:not(:last-child){
                margin-right: 16px;
            }
        }

        .footer__bottom-logo{
            width: 70px;

            @media screen and ( max-width: 626px){
                width: 50px;
            }
        }
    }

}


