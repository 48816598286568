.container__back-blue{
    background: #272b47;
}

.section{
    display: flex;
    justify-content: space-between;
    padding-top: 70px;
    width: 100%;

    @media screen and (max-width: 1229px){
        flex-wrap: wrap;
    }

    @media screen and ( max-width: 860px){
        padding-top: 30px;
    }

    .section__boxes{
        display: flex;

        @media screen and (max-width: 1229px){
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }

        @media screen and (max-width: 733px){
            flex-direction: column;
        }
        
    }

    .jojo{
        background-image: url(../../../../assets/img/Bearjojo.jpg);
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 1s ease;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
    }

    .school{
        background-image: url(../../../../assets/img/School.jpg);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 1s ease;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
    }

    .section__link{
        border: 5px solid #DBDEE3;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        cursor: pointer;
        padding: 48px;
        position: relative;
        min-height: 404px;
        width: 303px;
        margin-left: 15px;

        @media screen and (max-width: 1229px){
            margin-left: 0px;
        }

        @media (max-width: 793px) {
            padding: 22px;
            width: 282px;
            min-height: 353px;
        }

        @media screen and (max-width: 733px){

            &:first-child{
                margin-bottom: 33px;
            }
        }
    
        &:hover{
            transform: translateY(-15px);
            -webkit-transform: translateY(-15px);
            -moz-transform: translateY(-15px);
            -ms-transform: translateY(-15px);
            -o-transform: translateY(-15px);
            background: #DBDEE3;
            transition: all 1s ease;
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -ms-transition: all 1s ease;
            -o-transition: all 1s ease;
                    
            span{
                border-bottom: 1px solid #272b47;
            }

            h2,p,h4,span{
                color: #272b47;
            }
        }
    }

    h2{
        font-weight: 300;
        font-size: 50px;
        line-height: 58px;
        letter-spacing: -0.5px;
        color: #DBDEE3;
        margin-bottom: 70px;

        @media screen and (max-width: 1513px){
            display: block;
            margin-left: 20px;
        }

        @media screen and (max-width: 1229px){
            display: block;
            margin: 0 auto;
            text-align: center;
        }

        @media screen and ( max-width: 860px){
            margin-bottom: 40px;
        }


    }
    
    span{
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: transparent;
        padding-bottom: 5px;
        border-bottom: 1px solid transparent;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
    }

    h4{
        font-size: 22px;
        line-height: 38px;
        font-weight: 500;
        letter-spacing: 0px;
        margin-top: 101px;
        color: transparent;
    }

    p{
        font-weight: 400;
        letter-spacing: 0px;
        font-size: 16px;
        line-height: 30px;
        position: absolute;
        bottom: 48px;
        left: 48px;
        padding-right: 48px;
        color: transparent;

        @media screen and (max-width: 982px){
            bottom: 22px;
            left: 22px;
            padding-right: 22px;
        }
    }
}