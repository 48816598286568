.vid_part{
    background: #272b47;
}

.video__card{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 2;
    height: 90vh;
}

.video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sm100{
    height: 100vh;
    transform: translateY(15px) scale(0.8) ;
    -webkit-transform: translateY(15px) scale(0.8) ;
    -moz-transform: translateY(15px) scale(0.8) ;
    -ms-transform: translateY(15px) scale(0.8) ;
    -o-transform: translateY(15px) scale(0.8) ;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.sm200{
    transform: translateY(66px) scale(0.7) ;
    -webkit-transform: translateY(66px) scale(0.7) ;
    -moz-transform: translateY(66px) scale(0.7) ;
    -ms-transform: translateY(66px) scale(0.7) ;
    -o-transform: translateY(66px) scale(0.7) ;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.sm300{
    transform: translateY(111px) scale(0.6) ;
    -webkit-transform: translateY(111px) scale(0.6) ;
    -moz-transform: translateY(111px) scale(0.6) ;
    -ms-transform: translateY(111px) scale(0.6) ;
    -o-transform: translateY(111px) scale(0.6) ;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.sm400{
    transform: translateY(50px) scale(0.7) ;
    -webkit-transform: translateY(50px) scale(0.7) ;
    -moz-transform: translateY(50px) scale(0.7) ;
    -ms-transform: translateY(50px) scale(0.7) ;
    -o-transform: translateY(50px) scale(0.7) ;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.sm500{
    transform: translateY(0px) scale(1);
    -webkit-transform: translateY(0px) scale(1);
    -moz-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    -o-transform: translateY(0px) scale(1);
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}

