.careersPage{
    background-color: #DBDEE3;
    padding: 70px 0;

    @media screen and (max-width: 860px){
        padding: 30px 0;
    }
}

.job__card{
    display: flex;
    justify-content: space-between;
    align-items: top;

    @media screen and (max-width: 860px){
        flex-wrap: wrap;
        align-items: center;
    }

    &-left{
        display: flex;
        flex-direction: column;
        width: 100%;

        .job__card-title{
            font-family: Brutal_Light, 'Open Sans', arial;
            font-weight: 400;
            font-size: 50px;
            line-height: 58px;
            margin-bottom: 15px;
            color: #272b47;
            text-decoration: underline;
            text-decoration-color: rgb(0, 87, 217);
            -moz-text-decoration-color: rgb(0, 87, 217);

            @media screen and ( max-width: 860px){
                font-size: 40px;
            }
            
            @media screen and ( max-width: 626px){
                font-size: 33px;
                line-height: 40px;
            }
        }

        h3{
            color: #272b47;
            font-weight: 600;
            font-size: 25px;
            padding: 15px 0;

            
            @media screen and ( max-width: 626px){
                font-size: 20px;
            }
        }

        p{
            color: #272b47;
            padding-left: 22px;

            
            @media screen and ( max-width: 626px){
                font-size: 15px;
                padding-left: 11px;
            }
        }
    }

    &-img{
        width: 100%;
        height: 100%;

        @media screen and (max-width: 860px){
            text-align: center;
        }
        
        img{
            float: right;
            width: 100%;
            object-fit: cover;

            @media screen and (max-width: 860px){
                float: none;
                width: 55%;
            }
        }
    }
}