.second_part {
    background-color: #dbdee3;
    padding: 95px 0 0 0;
    font-family: Brutal_Regular, 'Open Sans', arial;
    font-weight: 400;

    @media (max-width: 860px) {
      padding: 70px 0 0 0;
    }
  
      .head{
      max-width: 700px;
      margin: 0 auto;
      text-align: center;
      h3 {
        font-family: Brutal_Light, 'Open Sans', arial;
        font-weight: 300;
        font-size: 50px;
        padding-bottom: 30px;
        line-height: 58px;
        color: #272b47;
        @media (max-width: 900px) {
          font-size: 38px;
          line-height: 46px;
        }
        @media (max-width: 793px) {
          font-size: 38px;
          line-height: 46px;
        }
      }
      p {
        color: #272b47;
        line-height: 30px;
      }
    }
    .card_part {
      padding-bottom: 40px;
      display: flex;
      justify-content: space-between;
      margin-top: 70px;

      @media (max-width: 860px) {
        margin-top: 30px;
        padding-bottom: 0px;
      }
      
      .card {
        width: calc(100% / 3);
        // -webkit-box-shadow: 0px 11px 33px 11px rgba(39,43,71,0.59);
        // box-shadow: 0px 11px 33px 11px rgba(39,43,71,0.59);
        // border-radius: 5px;
        // -webkit-border-radius: 5px;
        // -moz-border-radius: 5px;
        // -ms-border-radius: 5px;
        // -o-border-radius: 5px;
      }

      @media screen and (max-width: 900px) {
        flex-wrap: wrap;
        justify-content: center;
        .card {
          min-width: 45%;
        }
      }
      @media (max-width: 793px) {
          justify-content: center;
          .card {
            min-width: 95%;
          }
        }
      
    }
  }
  
  .card {
    position: relative;
    text-align: center;
    padding: 30px 0;
    margin: 12px;
    font-family: Brutal_Regular, 'Open Sans', arial;
    font-weight: 400;
    line-height: 30px;
    min-width: 240px;
    @media screen and ( max-width: 860px){
      margin: 0px;
    }
    @media (max-width: 424px) {
      padding: 20px 0;
    }
    &::before {
      position: absolute;
      left: 0px;
      top: 0px;
      content: '';
      width: 100%;
      height: 2px;
      background: radial-gradient(
        100% 185.14% at 100% 100%,
        rgb(52, 195, 255) 0%,
        rgb(51, 142, 238) 100%
      );
    }
    h4 {
      font-size: 22px;
      padding-bottom: 20px;
      .link {
        color: #272b47;
        &:hover {
          transition: all .5s ease;
          color: rgb(0, 87, 217);
        }
      }
    }
    p {
      color: #272b47;
    }
    .btn{
      margin: 20px 0;
    }
    
  }
  