.careers-main{
    background-color: #DBDEE3;
    padding: 70px 0;

    @media screen and ( max-width: 860px){
        padding: 30px 0;
    }

    .careers{
        padding-top: 44px;

        @media screen and ( max-width: 860px){
            padding-top: 30px;
        }

        &__card{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 22px;

            @media screen and ( max-width: 626px){
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }

            h2{
                display: block;
                width: 20%;
                font-size: 24px;
                color: #272b47;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 1.42;

                @media screen and ( max-width: 717px){
                    font-size: 20px;
                }

                @media screen and ( max-width: 626px){
                    width: 100%;
                }
            }

            p{
                color: #272b47;
                line-height: 22px;
                text-align: justify;
                width: 50%;

                @media screen and ( max-width: 626px){
                    width: 100%;
                    padding: 22px 0;
                    font-size: 15px;
                }
            }

            &:nth-child(1),&:nth-child(3){
                -webkit-box-shadow: 0px 1px 22px 1px rgba(39,43,71,0.59);
                box-shadow: 0px 1px 22px 1px rgba(39,43,71,0.59);
                background: linear-gradient(135deg, transparent 33px, #DBDEE3 0), linear-gradient(-135deg, transparent 33px, #272b47 0);
                border-radius: 3px;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                -ms-border-radius: 3px;
                -o-border-radius: 3px;
            }   

            .btn-padding{
                padding: 0;
            }
        }
    }
}