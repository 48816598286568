.join_background{
    background-color: #DBDEE3;
    padding: 70px 0;

    @media screen and (max-width: 860px){
        padding: 30px 0;
    }
}

.join{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    @media screen and (max-width: 860px){
        flex-direction: column;
        padding: 15px 0;
    }
    

    &__box{
        width: 55%;

        @media screen and (max-width: 860px){
            width: 100%;
        }

        .join__box-title{
            margin-bottom: 50px;

            h2{
                z-index: 2;
                display: block;
                text-align: left;

                @media screen and (max-width: 860px){
                    text-align: center;
                }

                strong{
                    color: #DBDEE3;
                    font-weight: 800;
                    background-color: #272b47;
                    padding: 9px;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;

                    @media screen and (max-width: 626px){
                        padding: 5px;
                    }
                }
            }
        }

        .join__box-card{
            text-align: left;
            background-color: #272b47;
            -webkit-box-shadow: 0px 1px 30px 1px rgba(39,43,71,0.59); 
            background: linear-gradient(225deg, transparent 25px, #272b47 0), linear-gradient(-225deg, transparent 25px, #DBDEE3 0);
            box-shadow: 0px 1px 30px 1px rgba(39,43,71,0.59);
            padding: 24px 40px;
            color: #DBDEE3;
            font-size: 20px;
            line-height: 26px;
            font-weight: 300;
            font-weight: 500;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;

            @media screen and ( max-width: 860px){
                padding: 22px;
            }

            @media screen and (max-width: 626px){
                font-size: 15px;
            }

            &:nth-child(1){
                background: linear-gradient(135deg, transparent 25px, #272b47 0), linear-gradient(-135deg, transparent 25px, #DBDEE3 0);
            }
        }
    }

    .join__box-img{
        width: 44%;
        height: 100%;

        @media screen and (max-width: 626px){
            width: 55%;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}